import { Link } from "react-router-dom";
import CreateButton from "../../components/buttons/CreateButton";


export function ProductBreadcrumb() {
    return (
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row pt-2 pb-4">
            <div>
                <h3 className="fw-bold mb-3">Product</h3>
                <h6 className="op-7 mb-2">Products information</h6>
            </div>
            <div className="ms-md-auto py-2 py-md-0">
                <CreateButton text="Add Product" link="/products/create" />
            </div>
        </div>
    );
}