import { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../header/Header";
import MainMenu from "../MainMenu";

export interface MainLayoutProps {
    children: React.ReactNode;
   
}

export default function MainLayout({ children }: MainLayoutProps) {

    return (
        <>
            <MainMenu />
            <div className="main-panel">
                <Header />
                {children}
                <Footer />
            </div>
        </>
    );
}