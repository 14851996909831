import PageContent from "../../components/layout/PageContent";
import { ProductBreadcrumb } from "./ProductBreadcrumb";
import { products } from "./temp";
import StandardTablePage from "../../components/layout/StandardTablePage";
import { DeleteButton } from "../../components/buttons/DeleteButton";
import EditButtonInList from "../../components/buttons/EditButtonInList";

export default function ProductList() {

    const Thead = (
        <tr>
            <th scope="col" style={{ width: '30px' }}>#</th>
            <th scope="col">Title</th>
            <th scope="col">TO DO</th>
            <th scope="col">TO DO</th>
            <th scope="col" className="text-end">Actions</th>
        </tr>
    );

    const Tbody = (
        <>
            {products.map(product => {
                return (
                    <tr key={product.id}>
                        <td className="text-center">{product.id}</td>
                        <td>{product.name}</td>
                        <td>TO DO</td>
                        <td>TO DO</td>
                        <td className="text-end">
                            <EditButtonInList href={"/products/" + product.id}/>
                            <DeleteButton />
                        </td>
                    </tr>
                );
            })}
        </>
    );

    return (
        <PageContent
            breadcrumbComponent={ProductBreadcrumb}
        >
            <StandardTablePage TheadContent={Thead} TbodyContent={Tbody} />
        </PageContent>
    );
}
