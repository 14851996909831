import { Link } from "react-router-dom";
import logoLight from '../assets/logos/logo_light.png'

export default function MainMenu() {
    console.log('Main menu');
    

    return (
        <>
            <div className="sidebar" data-background-color="dark">
                <div className="sidebar-logo">

                    <div className="logo-header" data-background-color="dark">
                        <Link to="" className="logo">
                            <img
                                src={logoLight}
                                alt="navbar brand"
                                className="navbar-brand"
                                height="20"
                            />
                        </Link>
                        <div className="nav-toggle">
                            <button className="btn btn-toggle toggle-sidebar">
                                <i className="gg-menu-right"></i>
                            </button>
                            <button className="btn btn-toggle sidenav-toggler">
                                <i className="gg-menu-left"></i>
                            </button>
                        </div>
                        <button className="topbar-toggler more">
                            <i className="gg-more-vertical-alt"></i>
                        </button>
                    </div>

                </div>
                <div className="sidebar-wrapper scrollbar scrollbar-inner">
                    <div className="sidebar-content">
                        <ul className="nav nav-secondary">
                            <li className="nav-item">
                                <Link to="/">
                                    <i className="fas fa-desktop"></i>
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/shops">
                                    <i className="fas fa-desktop"></i>
                                    <p>Shops</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/products">
                                    <i className="fas fa-file"></i>
                                    <p>Products</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}