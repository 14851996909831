import Breadcrumb, { BreadcrumbProps } from "../../components/layout/Breadcrumb";
import PageContent from "../../components/layout/PageContent";

const dashboardContentBreadcrumbProps: BreadcrumbProps = {
    title: 'Dashboard',
    items: []
}

function DashboardBreadcrumb() {
    return (
        <Breadcrumb {...dashboardContentBreadcrumbProps}/>
    );
}

export default function DashboardContent() {

    return (
        <PageContent breadcrumbComponent={DashboardBreadcrumb}>
            <h1>This will be some dashboard</h1>
        </PageContent>
    );
}
