import Breadcrumb, { BreadcrumbProps } from "../../components/layout/Breadcrumb"
import PageContent from "../../components/layout/PageContent";

const breadcrumbData: BreadcrumbProps = {
    title: 'Shop',
    items: [
        {
            title: 'Shop',
            link: '/shops'
        }
    ]
}

function ShopBreadcrumb() {
    return <Breadcrumb {...breadcrumbData}/>
}

export default function ShopList() {
    return (
        <PageContent breadcrumbComponent={ShopBreadcrumb}>
            <h1>This is shop</h1>
        </PageContent>
    );
}