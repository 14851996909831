import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Interface } from "readline";

export interface BreadcrumbProps {
    title: string,
    items: BreadcrumbItem[]
}

export interface BreadcrumbItem {
    title: string,
    link: string
}

export default function Breadcrumb({ title, items }: BreadcrumbProps) {
    return (
        <div className="page-header mb-0">
            <h3 className="fw-bold mb-3">{title}</h3>
            <ul className="breadcrumbs mb-3">
                <li className="nav-home">
                    <Link to="/">
                        <i className="home"></i>
                    </Link>
                </li>

                {items.map((item, index) => (
                    <Fragment key={index}>
                        <li className="separator" >
                            <i className="icon-arrow-right"></i>
                        </li>
                        <li className="nav-item">
                            <a href={item.link}>{item.title}</a>
                        </li>
                    </Fragment>
                ))}
            </ul>
        </div>
    );
}