
const products = [
    {
        id: 1,
        name: "Product"
    },
    {
        id: 2,
        name: "Product 2"
    },
    {
        id: 3,
        name: "Product 3"
    },
];

export {products}