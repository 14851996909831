import Pagination from "./Pagination";

export interface StandardTablePageProps {
    TheadContent: React.ReactNode;
    TbodyContent?: React.ReactNode;
    paginationData?: any;
}


export default function StandardTablePage({ TheadContent, TbodyContent, paginationData }: StandardTablePageProps) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body table-responsive">
                        <table className="table table-striped mt-3">
                            <thead>
                                {TheadContent}
                            </thead>
                            <tbody>
                                {TbodyContent}
                            </tbody>
                        </table>

                        <div className="pt-5">
                            <Pagination />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}