import logoLight from '../../assets/logos/logo_light.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserProfileButton from './UserProfileButton';
import UserProfileMenu from './UserProfileMenu';
import React, { useEffect, useState } from 'react';

interface HeaderProps {
    profileMenuVisible: boolean;
    setProfileMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Header() {
    const [isProfileMenuVisible, setProfileMenuVisible] = useState(false);
    const { pathname } = useLocation()

    function handleProfileMenuVisible() {
        setProfileMenuVisible(isVisible => !isProfileMenuVisible)
    }

    useEffect(() => {
        setProfileMenuVisible(false)
    }, [pathname])

    // -> 

    return (
        <>
            <div className="main-header">
                <div className="main-header-logo">

                    <div className="logo-header" data-background-color="dark">
                        <Link to="index.html" className="logo">
                            <img
                                src={logoLight}
                                alt="navbar brand"
                                className="navbar-brand"
                                height="20"
                            />
                        </Link>
                        <div className="nav-toggle">
                            <button className="btn btn-toggle toggle-sidebar">
                                <i className="gg-menu-right"></i>
                            </button>
                            <button className="btn btn-toggle sidenav-toggler">
                                <i className="gg-menu-left"></i>
                            </button>
                        </div>
                        <button className="topbar-toggler more">
                            <i className="gg-more-vertical-alt"></i>
                        </button>
                    </div>

                </div>

                <nav
                    className="navbar navbar-header navbar-header-transparent navbar-expand-lg border-bottom"
                >
                    <div className="container-fluid">
                        <ul className="navbar-nav topbar-nav ms-md-auto align-items-center">
                            <li className="nav-item topbar-user dropdown hidden-caret">
                                <UserProfileButton profileMenuHandler={handleProfileMenuVisible} />
                                <UserProfileMenu showProfileMenu={isProfileMenuVisible} />
                            </li>
                        </ul>
                    </div>
                </nav>

            </div>
        </>
    );
}

