import { Route, Routes } from "react-router-dom";
import ProductList from "./ProductList";
import ProductForm from "./ProductForm";


export default function ProductRoutes() {
    return (
        <Routes>
            <Route path="" element={<ProductList />} />
            <Route path="create" element={<ProductForm />} />
            <Route path=":id" element={<ProductForm />} />
        </Routes>
    );
}