import { Link } from "react-router-dom";

export interface EditButtonInListProps {
    href: string;
}

export default function EditButtonInList({href}: EditButtonInListProps) {
    return (
        <Link className="btn btn-primary me-3" to={href}>Edit</Link>
    );
}