import React from 'react';
import logo from './logo.svg';
import './App.css';
import ProductList from './modules/product/ProductList';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ShopList from './modules/shop/ShopList';
import MainLayout from './components/layout/MainLayout';
import DashboardContent from './modules/dashboard/DashboardContent';
import ProductRoutes from './modules/product/ProductRoutes';
function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" element={<DashboardContent />} />
          <Route path="/products/*" element={<ProductRoutes />} />
          <Route path="/shops" element={<ShopList />} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;
