import userImg from '../../assets/img/profile.jpg'

export interface UserProfileButtonProps {
    profileMenuHandler: () => void
}

export default function UserProfileButton({profileMenuHandler}: UserProfileButtonProps) {
    return (
        <div
            className="profile-pic cursor-pointer"
            onClick={profileMenuHandler}
            role='button'
        >
            <div className="avatar-sm">
                <img
                    src={userImg}
                    alt="User profile image"
                    className="avatar-img rounded-circle"
                />
            </div>
            <span className="profile-username">
                <span className="op-7">Hi,</span>
                <span className="fw-bold">Hizrian</span>
            </span>
        </div>
    )
}