import { useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbProps } from "../../components/layout/Breadcrumb";
import PageContent from "../../components/layout/PageContent";
import productBreadcrumbData from "./breadcrumb-data";
import { log } from "console";
import { useRef, useState } from "react";

function ProductFormBreadCrumb() {
    return <Breadcrumb {...productBreadcrumbData} />
}



export default function ProductForm() {
    const { id } = useParams();
    const product = useRef( {
        name: "",
        price: 0,
        category: "",
        shortDescription: "",
        description: ""
    });

    const [productName, setProductName] = useState(product.current.name);

    function handleProductName(event: React.ChangeEvent<HTMLInputElement>) {
        setProductName(event.target.value);
    }

    function handleSubmit() {
        console.log(product);
    }

    return (
        <PageContent breadcrumbComponent={ProductFormBreadCrumb} >
            <div className="row">
                <div className="col-12">
                    <div className="card">

                        <div className="card-header">
                            <div className="card-title">
                                {id && "Update product: "}
                                {!id && "New product: "}
                                {productName}
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label >Product name</label>
                                        <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Product ..." 
                                        value={productName}
                                        onChange={handleProductName}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label >Category</label>
                                        <input type="text" className="form-control" placeholder="Chose category ..." />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label >Price</label>
                                        <input type="number" className="form-control" placeholder="10..." />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label >Short description</label>
                                        <textarea className="form-control" placeholder="Short description" rows={4} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label >Full description</label>
                                        <textarea className="form-control" placeholder="Short description" rows={10} />
                                    </div>
                                </div>
                            </div>

                            <div className="card-action">
                                <button className="btn btn-success" onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </PageContent>
    );
}
