import { Link } from 'react-router-dom';
import userImg from '../../assets/img/profile.jpg'

export interface UserProfileMenuProps {
    showProfileMenu: boolean;
}

export default function UserProfileMenu({ showProfileMenu }: UserProfileMenuProps) {
    return (
        <ul className={`dropdown-menu dropdown-user fadeIn ${showProfileMenu ? 'show' : ''}`}>
            <div className="dropdown-user-scroll scrollbar-outer">
                <li>
                    <div className="user-box">
                        <div className="avatar-lg">
                            <img
                                src={userImg}
                                alt="image profile"
                                className="avatar-img rounded"
                            />
                        </div>
                        <div className="u-text">
                            <h4>Hizrian</h4>
                            <p className="text-muted">hello@example.com</p>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="#">My Profile</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="#">Logout</Link>
                </li>
            </div>
        </ul>
    );
}