
export interface PaginationProps {
    page?: number;
    perPage?: number;
    totalItems?: number;
    totalPages?: number;
}

export default function Pagination({}: PaginationProps) {
    return (
        <nav aria-label="Page navigation">
            <ul className="pagination">
                <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                <li className="page-item active"><a className="page-link" href="#">1</a></li>
                <li className="page-item"><a className="page-link" href="#">2</a></li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item"><a className="page-link" href="#">Next</a></li>
            </ul>
        </nav>
    );
}