import { BreadcrumbProps } from "../../components/layout/Breadcrumb";

const productBreadcrumbData: BreadcrumbProps = {
    title: 'Product',
    items: [
        {
            title: 'Product',
            link: '/products'
        }
    ]
}

export default productBreadcrumbData;