import React from "react";

export interface PageContentProps {
    breadcrumbComponent: React.ElementType,
    children: React.ReactNode,
}

export default function PageContent({ breadcrumbComponent, children }: PageContentProps) {

    const BreadcrumbComponent = breadcrumbComponent;

    return (
        <div className="container">
            <div className="page-inner">
                <BreadcrumbComponent />
                {children}
            </div>
        </div>
    );
}