import React from 'react'
import { Link } from 'react-router-dom';

interface CreateButtonProps {
  text: string;
  link: string;
}

export default function CreateButton({ text, link }: CreateButtonProps) {
  return (
    <Link
      to={link}
      className="btn btn-primary btn-round">
      {text}
    </Link>
  )
}
